// 修改详细模式编辑界面
<template>
  <div class="detailed_model_content_add">
    <div class="detailed_model_content" v-if="showLoading == 1">
      <!-- 项目切换的导航组价 -->
      <ProjectNavBarAdd
        :projectArr="projectArr"
        @doItemClick="doItemClick"
      ></ProjectNavBarAdd>

      <!-- 用于显示切换项目组件的部分 -->
      <div class="show_project_com" :key="curPos + '--'">
        <!-- <el-scrollbar> -->
        <SubLevelAdd
          :subItem="item"
          :sid="sid"
          v-for="item in levelArr"
          :key="item.subId"
        ></SubLevelAdd>
        <!-- </el-scrollbar> -->
      </div>
    </div>

    <div class="detailed_model_content" v-if="showLoading == -1">
      <LoadingView></LoadingView>
    </div>
  </div>
</template>
<script>
//引入加载组件
import LoadingView from "@/components/Loading.vue";

//切换的导航栏
import ProjectNavBarAdd from "@/components/updateproject/ProjectNavBarAdd";

//引入新增的详细模式组件
import SubLevelAdd from "@/components/updateproject/common/SubLevelAdd";

export default {
  data() {
    return {
      //是否显示加载视图
      showLoading: -1,

      //导航条的数据
      projectArr: [],

      //导航栏的位置
      curPos: 0,

      //所有课程数据
      allLessonArr: [],

      //需要显示的子级别数组
      levelArr: [],
    };
  },

  created() {
    // console.log("传递的sid是:", this.sid);

    console.log("重新执行了该方法了");

    //请求导航条数据
    this.httpProjectData();
  },

  mounted() {},

  methods: {
    //项目切换
    doItemClick(pos) {
      //导航选中的位置
      this.curPos = pos;

      //同步一下当前子级别的数据
      this.levelArr = this.allLessonArr[this.curPos].subList;
      // console.log("当前列表数据是：", this.levelArr);
    },

    //查询所有平台信息 5号接口
    httpProjectData() {
      let param = this.getHttpParams();
      param.type = 5;
      param.sid = this.sid;
      this.$http.fetchPost(this.$api.SCHOOL_JURISDICTION, param).then((res) => {
        if (res.data.state == 200) {
          //取出所有课程信息
          let server = res.data.data;
          this.projectArr = server;

          //初始化平台id
          // this.pid = this.projectArr[this.curPos].pid;

          //请求所有课程数据
          this.httpLeesonData();
        } else {
          this.showErrorMsg(this.getMesage(res.data.state));
        }
      });
    },

    //查询详细模式可加盟信息 3号接口
    httpLeesonData() {
      let param = this.getHttpParams();
      param.type = 3;
      this.$http.fetchPost(this.$api.SCHOOL_JURISDICTION, param).then((res) => {
        let result = res.data;
        if (result.state == 200) {
          //获取所有级别的数据
          this.allLessonArr = result.data;
        //   console.log("获取的数据是:", this.allLessonArr);
          //组装数据
          this.initServerData();
        } else {
          this.showErrorMsg(this.getMesage(result.state));
        }
      });
    },

    //组装服务器数据
    initServerData() {
      if (!this.isEmpty(this.allLessonArr)) {
        this.allLessonArr.forEach((obj) => {
          let subList = obj.subList;

          if (!this.isEmpty(subList)) {
            //创建子级别的数据
            subList.forEach((item) => {
              // //正式账户
              let normal = item.normal;

              //试用账户
              let trial = item.trial;

              //isShow是否显示正式账户和试用账户前面的选中圈
              //choosed 正式和试用账户选中圈是否选中
              if (item.pid == 5) {
                //模考不显示试用账户
                this.$set(normal, "isShow", false);
                this.$set(normal, "choosed", true);
              } else {
                //都显示选中按钮
                this.$set(normal, "isShow", true);
                if (trial) {
                  this.$set(trial, "isShow", true);
                }

                //设置选中的值
                this.$set(normal, "choosed", false);
                if (trial) {
                  this.$set(trial, "choosed", false);
                }
              }

              //所有级别是否选中,默认是false,此值同样用于控制所有级别的有效账户数,账户有效期是否可操作
              this.$set(normal, "selected", false);

              //所有级别的教师选中时间,默认空数组
              this.$set(normal, "time", []);

              if (this.pid == 4 || this.pid == 5) {
                //程序蛙少儿编程和模考中心需要配置学生账户时间
                this.$set(normal, "studentTime", []);
              }

              //配置每个子级别的控制状态
              let levelArray = normal.levelArray;
              levelArray.forEach((levelItem) => {
                //每个子级别是否选中,默认false，此值同样用于控制所有级别的有效账户数,账户有效期是否可操作
                this.$set(levelItem, "selected", false);
                //子级别的教师选中时间,默认空数组
                this.$set(levelItem, "time", []);
                if (this.pid == 4 || this.pid == 5) {
                  //程序蛙少儿编程和模考中心需要配置学生账户时间
                  this.$set(levelItem, "studentTime", []);
                }
              });
            });
          }
        });

        //默认显示第curPos个位置的子级别数组
        this.levelArr = this.allLessonArr[this.curPos].subList;
        // console.log("创建好的数据是:", this.allLessonArr);

        //显示视图
        this.showLoading = 1;
      }
    },
  },
  computed: {},
  components: {
    LoadingView,
    ProjectNavBarAdd,
    SubLevelAdd,
  },

  //学校id
  props: ["sid"],
};
</script>
<style lang="scss" scoped>
.detailed_model_content_add {
  height: calc(100vh - 77px - 45px);
  margin: 0 14px;
  min-width: $tab_min_width;
  .detailed_model_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    //用于存放组件部分
    .show_project_com {
      flex: 1;
      height: calc(100vh - 77px - 130px);
      overflow-y: auto;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
  }

  //定义滚动条轨道  内阴影+圆角
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  //定义滑块内阴影+圆角
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
    background-color: #cccccc;
  }
}
</style>
