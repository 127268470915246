//极简模式
<template>
  <div class="min_content">
    <div class="content-center">
      <div class="line"></div>
      <!-- 课程加盟权限列表 -->
      <!-- 一级 -->
      <div v-for="(platListItem, index1) in CourseList.platList" :key="index1">
        <el-checkbox
          :value="platListItem.state == 1 ? true : false"
          :checked="platListItem.state == 1 ? true : false"
          @change="handleCheckOne(index1)"
          :label="platListItem.label"
        ></el-checkbox>

        <div class="line"></div>
        <div class="group">
          <!-- 二级 -->
          <div class="second_item" v-for="(subListItem, index2) in platListItem.subList" :key="index2" v-show="platListItem.subList.length != 0">
            <el-checkbox
              :value="subListItem.state == 1 ? true : false"
              :checked="subListItem.state == 1 ? true : false"
              @change="handleCheckTwo(index1, index2)"
              >{{ subListItem.label }}</el-checkbox
            >

            <div class="line_level"></div>

            <!-- 三级 -->
            <span class="group_level" v-for="(levelListItem, index3) in subListItem.levelList" :key="index3" v-show="subListItem.levelList.length != 0">
              <el-checkbox
                class="margin_left_20"
                :value="levelListItem.state == 1 ? true : false"
                :checked="levelListItem.state == 1 ? true : false"
                @change="handleCheckThree(index1, index2, index3)"
                >{{ levelListItem.label }}</el-checkbox
              >
            </span>
          </div>
        </div>
      </div>

      <div class="line"></div>
      <!-- 试用账户数 -->
      <div>
        <span>
          <el-radio v-model="radio" label="1">试用账户</el-radio>

          <span class="text">
            试用教师账户数:
            <el-input class="accountNumber" maxlength="6" v-model="accountNumber1" :disabled="disabled1" placeholder="请输入账户数" size="mini"></el-input>
            <font style="margin-left: 100px">试用学生账户数:</font>

            <el-input class="accountNumber" maxlength="6" :disabled="disabled1" v-model="accountNumber2" placeholder="请输入账户数" size="mini"></el-input>
          </span>
        </span>
      </div>
      <div class="line"></div>

      <!-- 正式账户 -->
      <div class="margin_top_20">
        <el-radio v-model="radio" label="2">正式账户</el-radio>
        <span class="text">
          有效教师账户数:
          <el-input class="accountNumber" maxlength="6" :disabled="disabled2" v-model="accountNumber3" placeholder="请输入账户数" size="mini"></el-input>
        </span>
        <span class="text1">
          账户有效期:
          <CommonDatePicker class="date-picker" v-model="searchDateRange1" :disabledDate="disabled2"> </CommonDatePicker>
        </span>
        <div class="line"></div>
        <div>
          <span class="text4">
            <font style="margin-left: 102px">有效学生账户数: </font>

            <el-input class="accountNumber" maxlength="6" :disabled="disabled2" v-model="accountNumber4" placeholder="请输入账户数" size="mini"></el-input>

            <span class="text1">
              账户有效期:
              <CommonDatePicker class="date-picker" v-model="searchDateRange2" :disabledDate="disabled2"> </CommonDatePicker>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div class="content-bottom">
      <el-button class="bt_item" size="medium" @click.stop="doCancel">返回列表</el-button>
      <el-button class="bt_item" size="medium" type="primary" :loading="showLoading" @click.stop="saveAction">保 存</el-button>
    </div>

    <!--  上传组件 -->
    <UpLoadingView v-if="showUploadView"></UpLoadingView>
  </div>
</template>
<script>
import CommonDatePicker from "../../components/CommonDatePicker.vue";

export default {
  name: "MinimalistMode",
  data() {
    return {
      //是否显示上传Loading的标识
      showUploadView: false,

      //试用账户1  正式账户2
      radio: null,
      //试用有效教师账户数
      accountNumber1: null,
      //试用有效学生账户数
      accountNumber2: null,
      //正式有效教师账户数
      accountNumber3: null,
      //正式有效学生账户数
      accountNumber4: null,
      //老师账户有效期
      searchDateRange1: [],
      //学生账户有效期
      searchDateRange2: [],
      //是否可输入
      disabled1: true,
      disabled2: true,

      //保存按钮状态
      showLoading: false,
      type: "-1",
      sid: "",

      //课程加盟集合
      CourseList: [],
      //json
      joinInfo: "",
    };
  },
  created() {
    //初始化数据
    this.initData();
    //获取加盟集合
    this.httpData();
  },
  methods: {
    //一级菜单按钮
    handleCheckOne(index1) {
      console.log(index1);
      console.log(this.CourseList.platList);

      if (this.CourseList.platList[index1].state == 0) {
        this.CourseList.platList[index1].state = 1;
        this.CourseList.platList[index1].subList.forEach((element) => {
          element.state = 1;
          element.levelList.forEach((element1) => {
            element1.state = 1;
          });
        });
      } else {
        this.CourseList.platList[index1].state = 0;
        this.CourseList.platList[index1].subList.forEach((element) => {
          element.state = 0;
          element.levelList.forEach((element1) => {
            element1.state = 0;
          });
        });
      }
    },
    // this.CourseList[index].levelList.forEach((element) => {
    //   element.state = 1;
    // });
    //二层菜单按钮
    handleCheckTwo(index1, index2) {
      console.log(index1);
      console.log(index2);
      event.stopPropagation();
      if (this.CourseList.platList[index1].subList[index2].state == 0) {
        this.CourseList.platList[index1].subList[index2].state = 1;
        this.CourseList.platList[index1].state = 1;

        this.CourseList.platList[index1].subList[index2].levelList.forEach((element) => {
          element.state = 1;
        });
      } else {
        this.CourseList.platList[index1].subList[index2].state = 0;
        this.CourseList.platList[index1].subList[index2].levelList.forEach((element) => {
          element.state = 0;
        });

        var temp1 = false;
        this.CourseList.platList[index1].subList.forEach((element) => {
          if (element.state == 1) {
            temp1 = true;
          }
        });
        if (!temp1) {
          this.CourseList.platList[index1].state = 0;
          console.log("触发" + this.CourseList.platList[index1].state);
        }
      }
    },
    //三层菜单按钮
    handleCheckThree(index1, index2, index3) {
      console.log(index1);
      console.log(index2);
      console.log(index3);
      event.stopPropagation();
      if (this.CourseList.platList[index1].subList[index2].levelList[index3].state == 0) {
        this.CourseList.platList[index1].state = 1;
        this.CourseList.platList[index1].subList[index2].state = 1;
        this.CourseList.platList[index1].subList[index2].levelList[index3].state = 1;
      } else {
        this.CourseList.platList[index1].subList[index2].levelList[index3].state = 0;

        var temp = false;
        this.CourseList.platList[index1].subList[index2].levelList.forEach((element) => {
          if (element.state == 1) {
            temp = true;
          }
        });

        if (!temp) {
          this.CourseList.platList[index1].subList[index2].state = 0;

          var temp2 = false;
          this.CourseList.platList[index1].subList.forEach((element) => {
            if (element.state == 1) {
              temp2 = true;
            }
          });
          if (!temp2) {
            this.CourseList.platList[index1].state = 0;
          }
        }
      }

      console.log(this.CourseList.platList);
    },

    //整理参数
    editParam() {
      //账号类型 1使用 2正式
      if (this.radio == "1") {
        this.state = "1";
        this.CourseList.trialTeacherNum = this.accountNumber1;
        this.CourseList.trialStudentNum = this.accountNumber2;
      } else if (this.radio == "2") {
        this.state = "0";
        this.CourseList.teacherNum = this.accountNumber3;
        this.CourseList.studentNum = this.accountNumber4;

        this.CourseList.teacherStartDate = this.getDate(this.searchDateRange1[0]);
        this.CourseList.teacherEndDate = this.getDate(this.searchDateRange1[1]);
        this.CourseList.studentStartDate = this.getDate(this.searchDateRange2[0]);
        this.CourseList.studentEndDate = this.getDate(this.searchDateRange2[1]);
      }

      this.joinInfo = JSON.stringify(this.CourseList);
    },

    //初始化数据
    initData() {
      //获取type,表示是编辑界面还是新增
      this.type = this.$route.query.type;

      //取出sid
      this.sid = this.$route.query.sid;
    },

    //取消
    doCancel() {
      //跳转到主界面
      this.$router.replace({
        path: "/Home/CampusManagement",
      });
    },
    //保存按钮
    saveAction() {
      //判断是否选中加盟产品
      var tem = false;
      this.CourseList.platList.forEach((element) => {
        if (element.state == 1) {
          tem = true;
        }
      });
      if (!tem) {
        // this.showWarnMsg("请完善加盟产品");
        this.showWarnMsg("未设置任何相关数据");
      }

      //判断选中账号类型
      if (this.radio != null) {
        //判断账户
        if (this.radio == 1) {
          if (this.accountNumber1 != null && this.accountNumber2 != null) {
            //请求接口
            this.httpSave();
          } else {
            this.showWarnMsg("请完善试用账户数");
          }
        } else {
          if (this.accountNumber3 != null && this.accountNumber4 != null) {
            if (this.searchDateRange1.length > 0 && this.searchDateRange2.length > 0) {
              //请求接口
              this.httpSave();
            } else {
              this.showWarnMsg("请完善课程有效期");
            }
          } else {
            this.showWarnMsg("请完善有效账号数");
          }
        }
      } else {
        this.showWarnMsg("请完善开通的账户类型");
      }
    },
    //请求保存接口
    httpSave() {
      //参数
      this.editParam();

      //进行保存操作

      let param = this.getHttpParams();
      param.type = 1;
      param.sid = this.sid;
      param.joinInfo = this.joinInfo;

      this.$http
        .fetchPost(this.$api.DETAILS_QUERY, param)
        .then((res) => {
          console.log("保存结果是:", res);
          if (res.data.state == 200) {
            //表示保存成功
            this.showSuccessMsg("保存成功");
            this.$router.replace({
              path: "/Home/CampusManagement",
            });
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log("上传失败", err);
        });
    },
    //请求课程可加盟权限
    httpData() {
      let param = this.getHttpParams();
      param.type = 4;
      this.$http
        .fetchPost(this.$api.DETAILS_QUERY, param)
        .then((res) => {
          console.log(res.data);
          if (res.data.state == 200) {
            //加盟数据
            this.CourseList = res.data.data;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log("上传失败", err);
        });
    },

    getDate(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return y + "-" + m + "-" + d;
    },
  },
  computed: {},
  components: {
    CommonDatePicker,
  },
  watch: {
    radio: function (newVal, oldVal) {
      if (this.radio == "1") {
        var temp = true;
        this.CourseList.platList.forEach((element) => {
          if (element.state == 1) {
            temp = false;
            this.disabled1 = false;

            this.disabled2 = true;
          }
        });

        if (temp) {
          this.disabled1 = true;

          this.disabled2 = true;
          this.showWarnMsg("请先选择加盟课程");
          this.radio = -1;
        }

        // //判断试用课按钮
        // if(this.CourseList.platList[index1].label=="创意建构启蒙"||
        // this.CourseList.platList[index1].label=="动力科技"||
        // this.CourseList.platList[index1].label=="Wedo机器人编程"||
        // this.CourseList.platList[index1].label=="程序蛙少儿编程"||
        // this.CourseList.platList[index1].label=="Python创意编程"||
        // this.CourseList.platList[index1].label=="悟空寻宝"||
        // this.CourseList.platList[index1].label=="编程情景小剧场"
        // ){
        //     this.disabled1 = false;
        // }
      } else if (this.radio == "2") {
        this.disabled2 = false;
        this.disabled1 = true;
      } else {
        this.disabled1 = true;
        this.disabled2 = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.min_content {
  height: calc(100vh - 77px - 45px);
  display: flex;
  flex-direction: column;
  .content-center {
    margin-left: 9%;
    overflow-y: scroll;
    height: calc(100vh - 75px - 45px - 106px);
  }

  //去除滚动条
  .content-center::-webkit-scrollbar {
    display: none;
  }

  .content-bottom {
    margin-top: 40px;
    margin-left: 25%;
    .bt_item {
      width: 110px;
      margin-left: 10%;
    }
  }
  .line {
    margin: 10px 0;
  }
  .line_level {
    margin: 10px 0;
  }
  .group {
    margin-left: 52px;
    .second_item {
      display: inline-block;
      margin-left: 6%;
    }
  }

  .group_level {
    margin-left: 72px;
    display: inline-block;
  }
  .accountNumber {
    width: 120px;
    height: 20px;
    margin-left: 5px;
  }
  .date-picker {
    margin-left: 5px;
    width: 280px;
    height: 30px;
  }
  .text {
    font-size: 13px;
    color: #666666;
    margin-left: 15px;
  }
  .text1 {
    font-size: 13px;
    color: #666666;
    margin-left: 95px;
  }
  .text2 {
    font-size: 13px;
    color: #666666;
    margin-left: 128px;
  }
  .text3 {
    font-size: 13px;
    color: #666666;
  }
  .text4 {
    font-size: 13px;
    color: #666666;
    margin-left: 28px;
  }
  .bt_minimalist {
    margin-top: 5px;
    margin-left: 20px;
  }
  .minima {
    margin-top: 5px;
    margin-left: 23px;
    padding: 8px 25px;
    background: #ffb545;
    color: white;
    border-radius: 10px;
  }
}
</style>