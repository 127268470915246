//项目切换的导航栏
<template>
  <div class="project_nav_bar_add">
    <div
      @click.stop="doItemClick(index)"
      :class="['item_nav', curpos == index ? 'nav_select' : 'nav_normal']"
      v-for="(item, index) in projectArr"
      :key="index"
    >
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curpos: 0,
    };
  },
  created() {},
  methods: {
    //条目点击事件
    doItemClick(index) {
      if (this.curpos != index) {
        this.curpos = index;
        this.$emit("doItemClick", index);
      }
    },
  },
  computed: {},
  components: {},
  props: ["projectArr"],
};
</script>
<style lang="scss" scoped>
.project_nav_bar_add {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .item_nav {
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    padding: 0 12px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .state_info {
    color: #45a312;
  }

  .nav_select {
    padding-top: 8px;
    padding-bottom: 8px;
    background: #ff9901;
    color: white;
  }

  .nav_normal {
    padding-top: 7px;
    padding-bottom: 7px;
    border: 1px solid $line_color;
  }
}
</style>
