//模式切换
<template>
  <div class="project_mode">
    <!-- 顶部的切换按钮 -->
    <div class="top_action">
      <span> 加盟课程: </span>

      <!--  极简模式显示时才显示顶部的模式切换按钮以及切换提示文字 -->
      <span class="minima" v-if="isShowEasy">{{ modeMsg }}</span>

      <el-button
        v-if="isShowEasy"
        class="bt_minimalist"
        type="text"
        @click="changeMode"
        >{{ showModeInfo }}</el-button
      >
    </div>

    <div class="content">
      <!-- 极简模式 -->
      <keep-alive>
        <MinimalistMode v-show="isShowEasy && showMode"></MinimalistMode>
      </keep-alive>
      <!-- 详细模式 -->
      <!-- <DetailedModel v-show="!showMode"></DetailedModel> -->

      <!-- 修改后的详细模式 -->
      <keep-alive>
        <DetailedModelAdd :sid="sid" v-if="!showMode"></DetailedModelAdd>
      </keep-alive>
    </div>
  </div>
</template>
<script>
//极简模式
import MinimalistMode from "./MinimalistMode.vue";

//详细模式
// import DetailedModel from "./DetailedModel.vue";

//修改后的详细模式
import DetailedModelAdd from "./DetailedModelAdd";

export default {
  data() {
    return {
      //极简模式的显示控制
      isShowEasy: true,

      //极简模式和详细模式的切换开关 true为极简 false为详细模式
      showMode: true,
      showModeInfo: "切换为详细模式",
      modeMsg: "极简模式",

      //是否是新增或者编辑的标识 1新增 2编辑
      type: "",

      //学校id
      sid: "",
    };
  },

  created() {},

  mounted() {
    //判断为新增还是编辑状态 1新增,2编辑
    this.type = this.$route.query.type;
    this.sid = this.$route.query.sid;

    if (this.type == 2) {
      //获取加盟状态,如果是待完善的状态,默认进来还是显示极简模式
      let state = this.$route.query.state;
      if (state != -3) {
        this.showMode = false;
        this.showModeInfo = "切换为极简模式";
        this.modeMsg = "详细模式";
        this.isShowEasy = false;
      }
    }
  },

  methods: {
    //模式切换
    changeMode() {
      this.showMode = !this.showMode;
      this.showMode
        ? (this.showModeInfo = "切换为详细模式")
        : (this.showModeInfo = "切换为极简模式");
      this.showMode ? (this.modeMsg = "极简模式") : (this.modeMsg = "详细模式");
    },
  },
  computed: {},
  components: {
    MinimalistMode,
    DetailedModelAdd,
    // DetailedModel,
  },
};
</script>
<style lang="scss" scoped>
.project_mode {
  width: 100%;
  height: calc(100vh - 77px);
  display: flex;
  flex-direction: column;

  .top_action {
    height: 45px;
    display: flex;
    align-items: center;
    margin: 0 20px;
    .bt_minimalist {
      margin-left: 20px;
    }
    .minima {
      margin-left: 23px;
      font-size: 14px;
      height: 30px;
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ff9901;
      color: white;
      border-radius: 4px;
    }
  }
  .content {
    flex: 1;
  }
}
//隐藏滚动栏
.project_mode::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
