//课程的通用子级别-新增
<template>
    <div class="sub_level_add" @click.stop="doOutClick">

        <div class="margin_left_14 margin_right_14 margin_top_10">

            <!-- 课程名称 -->
            <div class="text_fw">{{subItem.subName}}</div>

            <!-- 试用账户编辑 pid=5 模考不用显示试用账户  -->
            <TryAccountEdit v-if="subItem.pid!=5" :trial="trial" :pid="subItem.pid" ref="try_account_edit" @doChoosedAccount="doChoosedAccount"></TryAccountEdit>

            <!-- 分割线 -->
            <div class="line margin_top_14"></div>

            <!-- 正式账户编辑 -->
            <OfficialAccountEdit :pid="subItem.pid" :normal="normal" @doChoosedAccount="doChoosedAccount"></OfficialAccountEdit>

        </div>

        <!-- 保存按钮 -->
        <el-button :loading="showLoading" class="commit com_bt" @click.stop="doSaveAction">
            <span>保</span><span class="margin_left_4">存</span>
        </el-button>

    </div>
</template>
<script>
//引入测试账户的编辑组件
import TryAccountEdit from "@/components/updateproject/common/TryAccountEdit";

//引入正式账户的编辑组件
import OfficialAccountEdit from "@/components/updateproject/common/OfficialAccountEdit";

//引入混入
import EditProduct from "@/mixin/EditProduct.js";

export default {
    mixins: [EditProduct],

    data() {
        return {
            //测试账户数据
            trial: null,
            //正式账户数据
            normal: null,
            //保存时的加载loading
            showLoading: false,
        };
    },
    created() {
        //请求数据
        this.normal = this.subItem.normal;
        this.trial = this.subItem.trial;
    },
    methods: {
        //试用账户和正式账户的选中回调事件
        doChoosedAccount(type) {
            // console.log("触发点击事件了", type);

            if (type == 1) {
                //正式账户的选中事件
                if (this.normal.choosed) {
                    //选中
                    this.trial.choosed = false;
                    //清空试用账户的状态
                    this.clearTiralState();
                } else {
                    //取消
                    this.clearNormalState();
                }
            } else if (type == 2) {
                //试用账户的选中事件
                if (this.trial.choosed) {
                    //选中
                    this.normal.choosed = false;

                    //清空正式账户的状态
                    this.clearNormalState();
                } else {
                    //取消
                    //清空试用账户的状态
                    this.clearTiralState();
                }
            }
        },

        //保存的操作
        doSaveAction() {
            //判断数据是否完整
            if (this.judgeData(this.subItem.pid)) {
                // console.log("设置好的数据是:", this.subItem);
                //请求服务器上传数据
                this.showLoading = true;
                let param = this.getHttpParams();
                param.type = 10;
                param.sid = this.sid;
                param.addInfo = JSON.stringify(this.subItem);
                this.$http
                    .fetchPost(this.$api.SCHOOL_JURISDICTION, param)
                    .then((res) => {
                        // console.log("上传结果是", res);
                        this.showLoading = false;
                        let result = res.data;
                        if (result.state == 200) {
                            //上传成功,关闭新增弹框,刷新页面数据
                            this.showSuccessMsg("保存成功");
                        } else {
                            this.showErrorMsg(
                                this.getMesage(result.state) + ",保存失败"
                            );
                        }
                    });
            }
        },
        //外层点击事件,关闭试用范围的下拉框
        doOutClick() {
            this.$refs.try_account_edit.close();
        },
    },
    computed: {},
    components: {
        TryAccountEdit,
        OfficialAccountEdit,
    },
    //每个级别数据
    props: ["sid", "subItem"],
};
</script>
<style lang="scss" scoped>
.sub_level_add {
    margin-bottom: 16px;
    border: 1px dashed $line_color;
    border-radius: 2px;
    margin-right: 10px;
    position: relative;

    .commit {
        background: #409eff;
        width: 76px;
        height: 34px;
        display: flex;
        align-items: center;
        color: white;
        position: absolute;
        justify-content: center;
        right: 10px;
        top: 5px;
    }

    .line {
        border-top: 1px dashed $line_color;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .empty_view {
        min-height: 100px;
    }
    .trial_empty_view {
        min-height: 40px;
        border-bottom: 1px dashed $line_color;
    }

    .empty_tv {
        font-size: 12px;
        color: gray;
    }
}
</style>